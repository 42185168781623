.plans-hero {
  background: radial-gradient(106.61% 106.6% at 80.49% -6.6%, #B8E3FF 0%, #FFF 100%);
}

.resources {
  &-content {
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    max-width: 936px;
  }

  &-text {
    max-width: 457px;
  }

  &-list {
    li {
      display: flex;

      & + li {
        margin-top: 16px;
      }
      
      img {
        margin-right: 16px;
      }

      span {
        color: $blue-curious-950;
        font-weight: 500;
      }
    }
  }

  h2 {
    color: $neutral-950;
    font-weight: 700;
  }
}

.try-free {
  &-content {
    background-color: $blue-curious-50;
    border-radius: 16px;
    margin: 0 auto;
    padding: 80px;
  }

  h2 {
    color: $neutral-950;
    font-weight: 700;
  }

  p {
    max-width: 457px;
  }
}

@include responsive(991) {
  .resources {
    &-content {
      align-items: center;
      flex-direction: column;
    }

    &-text {
      max-width: 600px;
    }

    &-list {
      margin-top: 40px;
    }
  }
}

@include responsive(767) {
  .try-free {
    &-content {
      padding: 60px 32px;
    }
  }
}