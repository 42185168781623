.organization {
  &-list {
    display: flex;
  }

  &-item {
    background-color: $blue-curious-50;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 360px;
    padding: 32px;
    width: 100%;

    
    & + .organization-item {
      margin-left: 24px;
    }

    &-top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 80px;

      strong {
        color: #090E29;
        display: block;
        font-weight: 600;
        line-height: 2.16rem;
        max-width: 230px;
      }

      img {
        margin-left: 28px;
      }
    }
  }

  .box-content {
    margin-bottom: 48px;
  }
}

@include responsive(991) {
  .organization {
    &-item {
      &-top {
        align-items: flex-start;
        flex-direction: column-reverse;

        img {
          margin-bottom: 8px;
          margin-left: 0;
        }
      }
    }
  }
}

@include responsive(767) {
  .organization {
    &-list {
      display: grid;
      gap: 32px;
      grid-template-columns: 1fr 1fr;
    }

    &-item {
      padding: 32px 26px;
      width: 100%;

      & + .organization-item {
        margin-left: 0;
      }

      &-top {
        margin-bottom: 60px;
      }

      &:last-child {
        align-items: center;
        grid-column: 2 span;
        display: flex;
        flex-direction: column;
        margin-left: 0;
        max-width: 100%;

        .organization-item-top {
          align-items: center;

          strong {
            text-align: center;
          }
        }
      }
    }
  }
}

@include responsive(599) {
  .organization {
    &-list {
      gap: 16px;
    }
  }
}

@include responsive(479) {
  .organization {
    &-list {
      gap: 22px;
      grid-template-columns: 1fr;
    }

    &-item {
      &-top {
        margin-bottom: 40px;
      }

      &:last-child {
        align-items: flex-start;
        grid-column: 1;
        
        .organization-item-top {
          align-items: flex-start;

          strong {
            text-align: left;
          }
        }
      }
    }
  }
}