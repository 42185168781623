.templates {
  &-box {
    align-items: center;
    border-radius: 24px;
    background-color: $blue-curious-50;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    max-width: 934px;
    padding: 40px 48px;

    & + .templates-box {
      margin-top: 48px;
    }

    &-content {
      max-width: 360px;
    }

    .label-text {
      font-weight: 500;
      margin-bottom: 16px;
    }

    h3 {
      color: $neutral-950;
    }
  }

  .box-content-center {
    margin-bottom: 48px;
    margin-left: auto;
    margin-right: auto;
    max-width: 744px;
  }
}

@include responsive(991) {
  .templates {
    &-box {
      padding: 40px 38px;

      &-img {
        &:not(.left) {
          margin-left: 24px;
        }

        &.left {
          margin-right: 24px;
        }
      }
    }
  }
}

@include responsive(767) {
  .templates {
    &-box {
      align-items: center;
      flex-direction: column;
      max-width: 470px;
      padding: 40px 24px;

      &-img {
        overflow: hidden;

        &:not(.left) {
          margin-left: 0;
        }

        &.left {
          margin-right: 0;
        }

        img {
          left: -10px;
          position: relative;
        }
      }

      &-content {
        margin-top: 36px;
        max-width: 100%;
        order: 2;
      }

      &-img {
        order: 1;
      }
    }
  }
}