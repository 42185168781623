.toch-hero {
  background: radial-gradient(130.69% 130.61% at 50% -7.49%, #DFF0FF 58.59%, #7ACDFF 100%);

  &::after {
    content: '';
    background: url(../../src/img/toch-hero-decoration-bottom.svg) no-repeat top center / contain;
    bottom: -32px;
    display: inline-block;
    height: 32px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 129px;
  }

  &-decoration {
    bottom: -100px;
    height: 469px;
    position: absolute;
    right: -100px;
    width: 329px;
  }

  .container {
    position: relative;
  }
}

.toch-video {
  &-box {
    align-items: center;
    background-color: $blue-curious-50;
    border-radius: 16px;
    display: flex;
    height: 551.114px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    width: 930.17px;

    strong {
      color: #0897E9;
      font-size: 4.8rem;
      font-weight: 600;
      line-height: 5.76rem;
      text-align: center;
    }
  }
}

.integration {
  overflow: hidden;

  &-list {
    align-items: center;
    display: flex;

    span {
      color: $black;
    }
  }

  &-item {
    align-items: center;
    background-color: $blue-curious-50;
    border-radius: 16px;
    display: flex;
    padding: 32px;
    width: 360px;

    & + .integration-item {
      margin-left: 24px;
    }

    img {
      margin-right: 26px;
    }

    span {
      font-weight: 500;
    }
  }

  .container {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .box-content {
    margin-bottom: 40px;

    &-title {
      max-width: 744px;
    }
  }

  .btn {
    margin-top: 48px;
  }
}

@include responsive(1199) {
  .integration {
    &-list {
      max-width: 100%;
      overflow-x: auto;
      padding-bottom: 10px;

      &::-webkit-scrollbar {
        height: 5px;
      }
      
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 20px;
        overflow: hidden;
      }
      
      &::-webkit-scrollbar-thumb {
        background: $blue-curious-200;
        border-radius: 20px;
        overflow: hidden;
      }
      
      &::-webkit-scrollbar-thumb:hover {
        background: $blue-curious-100;
        border-radius: 20px;
        overflow: hidden;
      }
    }

    &-item {
      padding: 24px 28px;
      min-width: 296px;
      width: 296px;

      img {
        margin-right: 16px;
      }
    }
  } 
}

@include responsive(991) {
  .toch-video {
    &-box {
      height: 480px;

      strong {
        font-size: 3.8rem;
        line-height: 4.76rem;
      }
    }
  }
}

@include responsive(767) {
  .toch-video {
    &-box {
      height: 380px;
    }
  }
}

@include responsive(599) {
  .toch-video {
    &-box {
      height: 280px;

      strong {
        font-size: 2.8rem;
        line-height: 3.76rem;
      }
    }
  }

  .integration {
    &-item {
      min-width: 260px;
      width: 260px;

      & + .integration-item {
        margin-left: 16px;
      }
    }
  }
}