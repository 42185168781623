.btn {
  align-items: center;
  border: 1px solid transparent;
  border-radius: 8px;
  display: inline-flex;
  font-weight: 600;
  height: 58px;
  padding: 15px 40px;
  transition: 0.3s;

  @include hover() {
    &:hover {
      svg {
        left: 8px;
      }
    }
  }

  &-primary {
    background-color: $blue-curious-100;
    border-color: $blue-curious-100;
    color: $dark-90;
  }

  &-dark {
    background-color: $blue-curious-950;
    border-color: $blue-curious-950;
    color: $blue-curious-10;

    &-outline {
      background-color: transparent;
      border-color: $blue-curious-950;
      color: $blue-curious-950;
    }
  }

  svg {
    left: 0;
    margin-left: 10px;
    position: relative;
    transition: 0.3s left;
  }
}

@include responsive(1199) {
  .btn {
    padding: 15px 34px;
  }
}