.home-hero {
  background: url(../../src/img/home-bg.jpg)  no-repeat top center / cover;
  overflow: hidden;

  &-content {
    max-width: 554.14px;

    h1,
    p {
      color: $white;
    }

    h1 {
      margin-bottom: 16px;
    }
  }

  &-img {
    align-items: flex-end;
    display: flex;
    margin-left: 84.21px;
  }

  &-decoration {
    bottom: -154px;
    height: 437px;
    opacity: .1;
    overflow: hidden;
    position: absolute;
    right: -180px;
    width: 330px;
  }

  .container {
    display: flex;
    position: relative;
  }
}

.home-organization {
  position: relative;

  &::before {
    content: '';
    background: url(../../src/img/home-hero-decoration-bottom.png) no-repeat top center;
    display: block;
    height: 41px;
    left: 50%;
    position: absolute;
    top: -1px;
    transform: translateX(-50%);
    width: 140px;
    z-index: -1;
  }

  .btn-scroll {
    top: -12px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }
}

.home-resources {
  background: url(../../src/img/topics-bg.jpg) no-repeat top center / cover;
}

@include responsive(1441) {
  .home-hero {
    background-position-y: 80%;
  }
}

@include responsive(1199) {
  .home-hero {
    &-content {
      max-width: 474px;
    }

    &-img {
      align-items: center;
    }
  }
}

@include responsive(991) {
  .home-hero {
    &-content {
      max-width: 100%;
    }

    &-img {
      display: none;
    }
  }
}

@include responsive(767) {
  .home-resources {
    background-position-x: 50%;
  }
}

@include responsive(599) {
  .home-hero {
    &-img {
      max-width: 212px;
    }
  }
}