.container {
  margin: 0 auto;
  max-width: calc(1128px + 30px);
  padding: 0 15px;
  width: 100%;
}

@include responsive(1199) {
  .container {
    max-width: calc(94.2rem + 30px);
  }
}

@include responsive(991) {
  .container {
    max-width: calc(71.6rem + 30px);
  }
}

@include responsive(767) {
  .container {
    max-width: calc(55rem + 30px);
  }
}

@include responsive(599) {
  .container {
    max-width: calc(44.4rem + 30px);
  }
}

@include responsive(479) {
  .container {
    max-width: calc(35.6rem + 16px);
    padding: 0 8px;
  }
}

@include responsive(374) {
  .container {
    max-width: calc(30rem + 16px);
  }
}