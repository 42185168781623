.link-arrow {
  align-items: center;
  color: $dark-40;
  display: inline-flex;
  font-weight: 700;
  width: max-content;

  @include hover() {
    &:hover {
      svg {
        left: 8px;
      }
    }
  }

  svg {
    left: 0;
    margin-left: 8px;
    position: relative;
    transition: 0.3s left;
  }
}