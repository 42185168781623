h1,
h2,
h3,
h4,
h5 {
  color: $blue-curious-950;
  font-weight: 600;
}

.title-h1,
h1:not(.no-config) {
  font-size: 4.8rem;
  font-weight: 600;
  line-height: 5.76rem;
}

.title-h2,
h2:not(.no-config) {
  font-size: 3.2rem;
  line-height: 3.84rem;
}

.title-h3,
h3:not(.no-config) {
  font-size: 2.8rem;
  line-height: 3rem;
}

.title-h4,
h4:not(.no-config) {
  font-size: 2rem;
  line-height: 2.7rem;
}

@include responsive(991) {
  .title-h1,
  h1:not(.no-config) {
    font-size: 5rem;
    line-height: 6.2rem;
  }

  .title-h2,
  h2:not(.no-config) {
    font-size: 3.6rem;
    line-height: 4.4rem;
  }
}

@include responsive(767) {
  .title-h1,
  h1:not(.no-config) {
    font-size: 4.2rem;
    line-height: 5.4rem;
  }

  .title-h2,
  h2:not(.no-config) {
    font-size: 3.2rem;
    line-height: 4.2rem;
  }
}

@include responsive(599) {
  .title-h1,
  h1:not(.no-config) {
    font-size: 4rem;
    line-height: 5.2rem;
  }

  .title-h3,
  h3:not(.no-config) {
    font-size: 2.2rem;
    line-height: 3.2rem;
  }
}

@include responsive(479) {
  .title-h1,
  h1:not(.no-config) {
    font-size: 3.8rem;
    line-height: 4.5rem;
  }

  .title-h2,
  h2:not(.no-config) {
    font-size: 2.8rem;
    line-height: 3.4rem;
  }
}
