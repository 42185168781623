.headline {
  &-box {
    display: flex;
    justify-content: space-between;
  }

  &-content {
    max-width: 553px;
  }
  
  &-list {
    margin-top: 40px;
    max-width: 392px;

    li {
      display: flex;

      & + li {
        margin-top: 32px;
      }

      img {
        margin-right: 24px;
      }

      p {
        font-weight: 600;
        line-height: 2.16rem;
      }
    }
  }

  .box-content {
    &-title {
      font-weight: 700;
    }
  }
}

@include responsive(1199) {
  .headline {
    &-content {
      margin-left: 32px;
      max-width: 460px;
    }
  }
}

@include responsive(991) {
  .headline {
    &-box {
      align-items: center;
      flex-direction: column;
    }

    &-content {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-top: 36px;
      margin-left: 0;
      max-width: 600px;
    }

    &-img {
      overflow: hidden;

      img {
        left: -24px;
        position: relative;
      }
    }
  }
}

@include responsive(599) {
  .headline {
    &-list {
      li {
        img {
          margin-right: 16px;
        }
      }
    }

    &-img {
      img {
        max-width: 308px;
      }
    }
  }
}