.p-vt-medium {
  padding-bottom: 112px;
  padding-top: 112px;
}

.pb-medium {
  padding-bottom: 112px;
}

.pt-medium {
  padding-top: 112px;
}

.p-vt-small {
  padding-bottom: 56px;
  padding-top: 56px;
}

.pb-small {
  padding-bottom: 56px;
}

.pt-small {
  padding-top: 56px;
}

html.overflow,
html.overflow body {
  overflow: hidden;
}

body {
  background-color: $white;
  color: $neutral-950;
}

p {
  & + p {
    margin-top: 12px;
  }
}