@mixin hover() {
  @media (hover: hover) {
    @content;
  }
}

@mixin responsive($deviceWidth) {
  // ==== MAX. Breakpoints
  @if ($deviceWidth == 1279) {
    @media (max-width: 1279px) {
      @content;
    }
  }

  @if ($deviceWidth == 1199) {
    @media (max-width: 1199px) {
      @content;
    }
  }

  @if ($deviceWidth == 991) {
    @media (max-width: 991px) {
      @content;
    }
  }

  @if ($deviceWidth == 767) {
    @media (max-width: 767px) {
      @content;
    }
  }

  @if ($deviceWidth == 599) {
    @media (max-width: 599px) {
      @content;
    }
  }

  @if ($deviceWidth == 479) {
    @media (max-width: 479px) {
      @content;
    }
  }

  @if ($deviceWidth == 374) {
    @media (max-width: 374px) {
      @content;
    }
  }

  // ==== MIN. Breakpoint
  @if ($deviceWidth == 1441) { 
    @media (min-width: 1441px) {
      @content;
    }
  }

  @if ($deviceWidth == 1280) { 
    @media (min-width: 1280px) {
      @content;
    }
  }

  @if ($deviceWidth == 992) { 
    @media (min-width: 992px) {
      @content;
    }
  }

  @if ($deviceWidth == 600) { 
    @media (min-width: 600px) {
      @content;
    }
  }
}