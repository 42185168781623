.label-text {
  align-items: center;
  border-radius: 200px;
  display: inline-flex;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.9rem;
  padding: 8px 16px;

  &-br-small {
    border-radius: 2px;
  }

  &-dark {
    background-color: $dark-100;
    color: $blue-curious-100;

    &-20 {
      background-color: $dark-90;
      color: $blue-curious-100;
    }
  }

  &-light {
    background-color: $blue-curious-200;
    color: $blue-curious-950;

    &-20 {
      background-color: $blue-curious-200;
      color: $blue-curious-800;
    }
    
    &-30 {
      background-color: $blue-curious-100;
      color: $blue-curious-700;
    }
  }
}