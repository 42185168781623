.box-content {
  &-center {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    max-width: 744px;
    
    .box-content-title {
      max-width: 600px;
      text-align: center;
    }

    p {
      text-align: center;
    }
  }

  &-title {
    margin-bottom: 16px;
  }

  .label-text {
    margin-bottom: 16px;
  }

  .btn {
    margin-top: 40px;
  }
}

@include responsive(991) {
  .box-content {
    &-center-lg {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-left: auto;
      margin-right: auto;
      max-width: 640px;
      
      .box-content-title {
        text-align: center;
      }

      p {
        text-align: center;
      }
    }
  }
}

@include responsive(767) {
  .box-content {
    &-center-md {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-left: auto;
      margin-right: auto;
      max-width: 640px;
      
      .box-content-title {
        text-align: center;
      }

      p {
        text-align: center;
      }
    }
  }
}