@keyframes fade {
  from {
    opacity: 0;
  }
  
  to {
    opacity: 1;
  }
}

@keyframes toLeft {
  from {
    opacity: 0;
    transform: translate3d(50px,0,0);
  }
  
  to {
    opacity: 1;
    transform: translate3d(0,0,0);
  }
}

@keyframes toRight {
  from {
    opacity: 0;
    transform: translate3d(-50px,0,0);
  }
  
  to {
    opacity: 1;
    transform: translate3d(0,0,0);
  }
}

@keyframes toBottom {
  from {
    opacity: 0;
    transform: translate3d(0,-50px,0);
  }
  
  to {
    opacity: 1;
    transform: translate3d(0,0,0);
  }
}

@keyframes toTop {
  from {
    opacity: 0;
    transform: translate3d(0,50px,0);
  }
  
  to {
    opacity: 1;
    transform: translate3d(0,0,0);
  }
}