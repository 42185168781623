$primary-color: #222;
$accent-color: #FFF;

$blue-curious-10: #F3FAFE;
$blue-curious-50: #F0F8FF;
$blue-curious-100: #DFF0FF;
$blue-curious-200: #B8E3FF;
$blue-curious-700: #0061A5;
$blue-curious-800: #045388;
$blue-curious-950: #062B4B;
$dark-40: #066AA3;
$dark-90: #021E2F;
$dark-100: #010F17;
$dark-110: #00080C;
$neutral-950: #121212;
$black: #090E29;
$white: #FFF;

$primary-font: 'Inter', sans-serif;