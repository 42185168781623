.templates-hero {
  background: url(../../src/img/templates-bg.jpg)  no-repeat top center / cover;
  overflow: hidden;
  
  &-content {
    max-width: 554.14px;

    h1,
    p {
      color: $white;
    }

    h1 {
      margin-bottom: 16px;
    }
  }

  &-img {
    align-items: flex-end;
    display: flex;
    margin-left: 84.21px;
  }

  &-decoration {
    bottom: -135px;
    height: 462px;
    opacity: .08;
    overflow: hidden;
    position: absolute;
    right: -161px;
    opacity: 0.3;
    width: 330px;
  }

  .container {
    display: flex;
    position: relative;
  }
}

@include responsive(1199) {
  .templates-hero {
    &-content {
      max-width: 490px;
    }

    .container {
      align-items: center;
    }
  }
}

@include responsive(991) {
  .templates-hero {
    &-content {
      max-width: 100%;
    }

    &-img {
      display: none;
    }
  }
}