.hero {
  &-large,
  &-medium,
  &-small {
    position: relative;
  }

  &-large {
    padding-bottom: 154.50px;
    padding-top: 212px;
  }

  &-medium {
    padding-bottom: 135px;
    padding-top: 199px;

    .box-content-title {
      max-width: 100%;
    }

    p {
      max-width: 552px;
    }
  }

  &-small {
    padding-bottom: 112px;
    padding-top: 178px;
  }
}

@include responsive(991) {
  .hero {
    &-large,
    &-medium {
      padding-top: 192px;
    }
  }
}

@include responsive(599) {
  .hero {
    &-large,
    &-medium,
    &-small {
      padding-top: 162px;
    }

    &-large {
      padding-bottom: 110px;
    }
    
    &-medium {
      padding-bottom: 80px;
    }
    
    &-small {
      padding-bottom: 70px;
    }
  }
}