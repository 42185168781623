@import './config/variables';

*,
*::after,
*::before {
  border: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: $primary-font;
}

html,
body {
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.5rem;
}

ul {
  list-style: none;
}

button {
  border: none;
  cursor: pointer;
  display: inline-block;
  font-weight: inherit;
}

a {
  display: inline-block;
  text-decoration: none;
  transition: 0.3s color;
}

img {
  display: block;
  max-width: 100%;
}

p {
  font-weight: 400;
}