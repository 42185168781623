.try {
  &-box {
    align-items: center;
    background: radial-gradient(708.05% 101.37% at 22.89% 17.42%, #062B4B 5%, #010F17 100%);
    border-radius: 16px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: max-content;
    padding: 112px 192px;
  }

  &-text {
    margin-right: 123px;
    max-width: 360px;

    h2 {
      color: $blue-curious-10;
    }
  }
}

@include responsive(1199) {
  .try {
    &-box {
      justify-content: center;
      padding: 112px 60px;
      width: 100%;
    }

    &-text {
      margin-right: 120px;
    }
  }
}

@include responsive(991) {
  .try {
    &-box {
      flex-direction: column;
      padding: 60px 40px;
    }

    &-text {
      margin-bottom: 32px;
      margin-right: 0;
      max-width: 100%;

      h2 {
        text-align: center;
      }
    }
  }
}

@include responsive(767) {
  .try {
    &-box {
      padding: 40px 32px;
    }
  }
}