.faq {
  &:not(.no-bg) {
    background: linear-gradient(180deg, #F0F8FF 0%, #FFF 100%);
  }
  
  &-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 744px;
    width: 100%;
  }

  &-top {
    h2 {
      text-align: center;
    }
  }

  &-item {
    padding: 32px 0 calc(32px - 14px);

    &:not(:last-child) {
      border-bottom: 1px solid $blue-curious-200;
    }

    &-question {
      display: flex;
      justify-content: space-between;
      margin-bottom: 14px;

      strong {
        font-weight: 600;
      }

      button {
        align-items: center;
        background-color: transparent;
        border: 1.8px solid $blue-curious-700;
        border-radius: 50%;
        display: flex;
        height: 34px;
        justify-content: center;
        margin-left: 12px;
        margin-top: 4px;
        min-height: 34px;
        min-width: 34px;
        width: 34px;

        &:not(.active) {
          svg {
            left: -0.5px;
            top: 1px;
          }
        }

        &.active {
          svg {
            left: 0.5px;
            transform: rotate(180deg);
          }
        }
        
        svg {
          position: relative;
          transition: 0.3s transform;
        }
      }
    }

    &-response {
      padding-right: 30px;
      transition: 0.3s max-height;
      max-height: 0;
      overflow: hidden;

      &.active {
        max-height: 1200px;

        > div {
          animation: toTop 0.3s forwards;
          animation-delay: 0.2s;
        }
      }

      > div {
        opacity: 0;
        transition: 0.3s opacity;
        margin-bottom: 14px;
      }

      p {
        opacity: 0.8;
      }
    }
  }
}