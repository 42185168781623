.header {
  left: 0;
  position: absolute;
  top: 51.49px;
  width: 100%;
  z-index: 99;

  &.dark {
    .header-logo {
      svg {
        path {
          &.header-logo-circle {
            fill: #0897E9;
          }

          &:not(.header-logo-circle) {
            fill: $dark-110;
          }
        }
      }
    }

    .header-nav {
      ul {
        li {
          a {
            &:not(.btn) {
              &::after {
                background-color: $dark-110;
              }
            }
          }
        }
      }
    }

    .header-btn {
      &.active {
        span {
          background-color: $white;
        }
      }

      span {
        background-color: $dark-110;
      }
    }
  }

  &-overlay {
    background: rgba(0,0,0,0.9);
    display: none;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 8;
  }

  &-nav {
    ul {
      align-items: center;
      display: flex;
      justify-content: center;

      li {
        & + li {
          margin-left: 40px;
        }

        @include hover {
          &:not(.btn).active {
            a {
              &::after {
                width: 100%;
              }
            }
          }
        }

        a {
          position: relative;

          &:not(.btn) {
            color: $white;
            font-weight: 500;
            transition-property: opacity;

            &::after {
              content: '';
              background-color: $blue-curious-50;
              bottom: -8px;
              height: 1px;
              left: 50%;
              position: absolute;
              transform: translate3d(-50%,0,0);
              transition: 0.3s width;
              width: 0;
            }

            @include hover {
              &:hover {
                &::after {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }

  &-btn {
    background-color: transparent;
    display: none;
    height: 44px;
    overflow: hidden;
    padding: 6px;
    position: relative;
    width: 44px;
    z-index: 99;

    &.active {
      span {
        &:nth-child(1) {
          transform: rotate(45deg);
        }

        &:nth-child(2) {
          display: none;
        }

        &:nth-child(3) {
          margin-top: -3px;
          transform: rotate(-225deg);
        }
      }
    }

    span {
      background-color: $white;
      border-radius: 4px;
      display: block;
      height: 3px;
      transition: 0.3s;
      width: 100%;

      & + span {
        margin-top: 8px;
      }
    }
  }

  .container {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}

@include responsive(1199) {
  .header {
    &-nav {
      ul {
        li {
          & + li {
            margin-left: 26px;
          }
        }
      }
    }
  }
}

@include responsive(992) {
  .header {
    &.dark {
      .header-nav {
        ul {
          li {
            a {
              &:not(.btn) {
                color: $dark-110;
              }
            }
          }
        }
      }
    }
  }
}

@include responsive(991) {
  .header {
    top: 36px;

    &-overlay {
      &.active {
        display: block;
      }
    }

    &-nav {
      background-color: $dark-100;
      height: 100vh;
      display: block;
      left: -120%;
      transition: 0.3s;
      padding-top: 120px;
      position: absolute;
      top: -36px;
      width: 300px;
      z-index: 99;

      &::before {
        content: '';
        background: url(../../src/img/header-logo.svg) no-repeat top center;
        display: block;
        position: absolute;
        height: 43px;
        top: 36px;
        left: 26px;
        width: 128px;
      }

      &.active {
        left: 0;
      }

      ul {
        align-items: flex-start;
        height: 100%;
        justify-content: flex-start;
        flex-direction: column;
        overflow-y: auto;
        padding-bottom: 20%;

        li {
          width: 100%;

          & + li {
            margin-left: 0;
            margin-top: 16px;
          }

          &:not(.btn).active {
            a {
              color: $blue-curious-200;
            }
          }

          a {
            &:not(.btn) {
              padding: 8px 26px;
              width: 100%;
            }

            &::after {
              display: none;
            }

            &.btn {
              margin-left: 26px;
              padding: 15px 20px;
              width: calc(100% - 52px);
            }
          }
        }
      }
    }

    &-btn {
      display: block;
    }
  }

  @media (orientation: landscape) {
    .header {
      flex-direction: row;

      &-nav {
        ul {
          padding-bottom: 20%;
        }
      }
    }
  }
}

@include responsive(599) {
  .header {
    top: 28px;

    &-nav {
      width: 280px;
    }
  }
}