.topics {
  &-left {
    max-width: 457px;
    width: 100%;
  }

  &-right {
    display: flex;
    margin-left: 32px;
    max-width: 552px;
    width: 100%;
  }

  &-list {
    & + .topics-list {
      margin-left: 24px;
      margin-top: 59.5px;
    }
  }

  &-item {
    align-items: center;
    background-color: rgba(2,30,47, 0.6);
    display: flex;
    height: 95px;
    padding: 16px 24px;
    
    & + .topics-item {
      margin-top: 24px;
    }

    img {
      margin-right: 24px;
    }

    span {
      color: $white;
      display: block;
      line-height: 2.24rem;
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
  }

  .box-content {
    &-title {
      color: $blue-curious-10;
    }

    p {
      color: $white;
    }
  }
}

@include responsive(1199) {
  .topics {
    &-left {
      max-width: 386px;
    }
  }
}

@include responsive(991) {
  .topics {
    position: relative;

    &-left {
      max-width: 418px;
      position: sticky;
      top: 20px;
    }

    &-right {
      flex-direction: column;
      max-width: 264px;
    }

    &-list {
      & + .topics-list {
        margin-left: 0;
        margin-top: 24px;
      }
    }

    .container {
      align-items: flex-start;
    }
  }
}

@include responsive(767) {
  .topics {    
    &-left {
      max-width: 100%;
      position: initial;
    }

    &-right {
      gap: 24px;
      grid-template-columns: 1fr 1fr;
      display: grid;
      margin-left: 0;
      margin-top: 36px;
      max-width: 100%;
    }

    &-list {
      & + .topics-list {
        margin-top: 0;
      }
    }

    .container {
      flex-direction: column;
    }
  }
}

@include responsive(599) {
  .topics {
    &-right {
      grid-template-columns: 1fr;
      margin-top: 44px;
    }

    &-item {
      width: 284px;
      margin: 0 auto;
    }
  }
}